.with-shadow {
    background: #fefefe;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.06);
    margin-bottom: 30px;

    * {
        // color:@text-color !important;
    }

    h1,h2 {
        position:relative;
        margin-bottom:10px;
        padding-bottom:10px
    }

    h2:after,
    h1:after {
        content: "";
        height: 1px;
        background: #348899;
        width: 100px;
        position: absolute;
        left: 0;
        bottom: 0;
    }
}

.with-box {
    padding: 20px;
    border:1px solid rgba(0,0,0,0.08);
    margin-bottom: 30px;
}

.shadow-template-container {
    width:100% !important;
    padding:0;

    .with-shadow {
        padding:0;
        border:0;
    }

    .content-row {
        padding-left:30px;
        padding-right:30px
    }
}

.videoWrapper {
    position: relative;
    padding-bottom: 56.25%; /* 16:9 */
    padding-top: 25px;
    height: 0;
}
.videoWrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.fb-image-white-border{
    padding:4px;
    border:1px solid rgba(0,0,0,0.1);
    background:#fff;
}

.fb-image-black-border{
    padding:4px;
    border:1px solid rgba(0,0,0,0.1);
    background:#000;
}

.fb-image-white-border-large{
    box-shadow: 0 0 20px 10px rgba(0,0,0,.1);
    border: 10px solid #fff;
}

.fb-image-black-border-large{
    box-shadow: 0 0 20px 10px rgba(0,0,0,.1);
    border: 10px solid #000;
}