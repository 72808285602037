.bg-white,.content-row {
    background: #fff;
}

.bg-grey {
    background: #fafafa
}

.bg-dark {
    background: #323232
}

.content-row {background-size:cover !important;}
.height-auto { height:auto;}
.height-20 { min-height:20vh}
.height-30 { min-height:30vh}
.height-40 { min-height:40vh}
.height-50 { min-height:50vh}
.is-section-50 { min-height:50vh}
.height-60 { min-height:60vh}
.height-70 { min-height:70vh}
.height-80 { min-height:80vh}
.height-90 { min-height:90vh}
.height-100 { min-height:100vh}
.height-100px {min-height:100px}
.height-200px {min-height:200px}
.height-300px {min-height:300px}
.height-400px {min-height:400px}
.height-500px {min-height:500px}
.height-600px {min-height:600px}
.height-700px {min-height:700px}
.height-800px {min-height:800px}
.uk-container {width:96%;padding:0 2%}
.uk-container-expand {max-width:100%;width:100% !important;padding:0}
// .uk-container {width:1200px;}
// .uk-container-large {width:1600px;}
.uk-container > .uk-grid {position:relative;z-index:2;}
#app .padding-none {padding-top:30px}
#app .padding-small {padding-top:30px}

.padding-none {padding:0}
.padding-small {padding:15px 0}
.padding-medium {padding:30px 0;}
.padding-large {padding:60px 0;}
.padding-extra-large {padding:100px 0;}

.content-row-overlay {position:absolute;top:0;bottom:0;height:100%;width:100%;z-index: 1}
.overlay-black .content-row-overlay {background:#000}
.overlay-white .content-row-overlay {background:#fff}
.overlay-dark .content-row-overlay {background:fade(#000, 50%)}
.overlay-darkest .content-row-overlay {background:fade(#000, 75%)}
.overlay-darkest .content-row-overlay {background:fade(#000, 75%)}
.overlay-dark-95 .content-row-overlay {background:fade(#000, 95%)}
.overlay-light .content-row-overlay {background:fade(#fff, 50%)}
.overlay-lightest .content-row-overlay {background:fade(#fff, 75%)}
.overlay-light-95 .content-row-overlay {background:fade(#fff, 95%)}
.overlay-none .content-row-overlay {display:none;}

.bg-position-top {background-position: top center}
.bg-position-right {background-position: right center}
.bg-position-bottom {background-position: bottom center}
.bg-position-left {background-position: left center}
.bg-position-center {background-position: center center}
.bg-position-fixed {background-attachment:fixed !important;}

.spacer-block > div {
	min-height: 120px
}

figure:focus {
	outline:none
}

.content-block {
	h1 {
		margin-top:0 !important
	}
}

.content-block {p {margin-top:0 !important}}
ol {
	li {
		list-style-type:decimal
	}
}

.content-row {
	.uk-container {
		position:relative;
		z-index:2;
	}
}

.uk-lightbox-iframe {
	width:96%;
	height:96%;
}

.fb-cursor {
	cursor: pointer;
}