// Base
@import "feelbettr/variables.less";
@import "themes/uikit-min-theme-default.less";
@import "themes/base.less"; 
@import "themes/header-overlay.less";
@import "themes/typography.less";
@import "themes/contact.less";

@bg-color: #fafafa;
@link-hover-color: @primary;
@global-font-size: 14px;
@global-font-family:  sans-serif;

h2.line-behind {
	position:relative;
	background:#fff;
	
	span {
		background:#fff;
		display:inline-block;
		position:relative;
		z-index:2;
		padding:0 15px 0 15px;
		font-size:inherit;
		text-transform: uppercase
	}

	&:after {
		content:"";
		height:1px;
		background:rgba(0,0,0,0.1);
		width:100%;
		position:absolute;
		left:0;
		top:55%;
		z-index:1;
	}
}

.first-row-with-padding {
	padding-top:160px !important;

	.is-section:first-child {
		padding-top:150px;

		@media screen and (max-width:@breakpoint-medium) {
			padding-top:190px
		}
	}
}

.uk-light {
	.uk-navbar-nav {
		li.uk-active {

			border-bottom:1px solid #fff;

			li {
				border: 0
			}

			a {
				color:#fff;
				
			}
		}

		li li.uk-active {
			border:0
		}
	}
}

.uk-dark {
	.uk-navbar-nav {
		li.uk-active {
			border-bottom:1px solid @text-color;

			li {
				border: 0
			}

			a {
				color:@text-color;
			}
		}

		li li.uk-active {
			border:0
		}
	}
}

footer.uk-light {
	a {
		color:#fff !important;

		&:hover {
			color:#999 !important
		}
	}
}

@media screen and (max-width:787px) {
	.uk-container {
		width:88%
	}
}

.header-language-selector {
    button {
        background:rgba(0,0,0,1) !important;
    }
}