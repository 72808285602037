.contact-form {
	label {
		text-transform: uppercase;
		font-size:10px;
		position:absolute;
		margin:4px 0 0 10px
	}

	input {
		padding-top:16px;
		height:52px;
		background:#fff;
		border:1px solid rgba(0,0,0,0.1);
		color:#000;
		border-radius:3px;
		font-size:14px;

		&:focus {
			color:#000;
			border-color:rgba(0,0,0,0.4);
			background:#fff
		}
	}

	input[type="submit"] {
		padding:0 10px;
		height:auto
	}

	textarea {
		min-height:200px;
		padding-top:30px;
		background:#fff;
		border:1px solid rgba(0,0,0,0.1);
		color:#000;
		border-radius:3px;
		font-size:14px;

		&:focus {
			color:#000;
			border-color:#000;
			background:#fff
		}
	}
}