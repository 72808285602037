
.social-links {
	a {
		&:hover {
			text-decoration:none;
			// color:@text-color
		}
	}
}


b,strong {
	font-weight:bold
}

h3.fb-with-border {
	position:relative;
	border-bottom:1px solid rgba(0,0,0,0.06);
	height:20px;
	margin-bottom:50px;

	span {
		background:@bg-color;
		padding:10px 30px;
		position:relative;
		z-index:2
	}

	&:after {
		position:absolute;
		content:"";
		left:0;
		bottom:-2px;
		background:#fff;
		width:100%;
		height:1px;
		z-index:1;
	}
}

a {
	&.fb-no-underline {
		&:hover {
			text-decoration:none;

			* {
				text-decoration:none;
			}
		}
	}
}

em {
	color:rgba(0,0,0,0.6);
}

.fb-underline-remove {
	&:hover {
		text-decoration:none
	}
}

.fb-remove-padding-left {
	a {
		padding-left:0 !important;
	}
}

.fb-remove-padding-right {
	a {
		padding-right:0 !important;
	}
}

.fb-menu-margin-right {
	li {
		margin-right:30px;
	}
}

.white-overlay {
	background:fade(#fff, 90%);

	.uk-modal-dialog {
		background:none
	}

	a {
		color:@text-color;
		font-weight:bold;
		font-size:20px;

		&:hover {
			color:@link-hover-color
		}
	}
}

h1,h2,h3 {
	a {
		color:@text-color;

		&:hover {
			color:@link-hover-color;
			text-decoration: none
		}
	}
}

.force-uk-light {
	* {
		color:#fff !important
	}
}

.full-width {
    width:100%
}

.fb-text-color {
	color:@text-color !important
}

.past-event-label {
    position: absolute;
    top:10px;
    right:10px;
    z-index:100;
    background:rgba(0,0,0,0.6);
    color:#fff;
    font-size:11px;
    padding:0px 4px;
    border-radius:3px;
    text-transform:uppercase
}

h2 {
    a {
        word-break: break-word;
    }
}

.horizontal-card-item.archived-event-item {
    h2 {
        padding-right:100px
    }
}