@internal-form-select-image:                     "~uikit/src/images/backgrounds/form-select.svg";
@internal-form-radio-image:                      "~uikit/src/images/backgrounds/form-radio.svg";
@internal-form-checkbox-image:                   "~uikit/src/images/backgrounds/form-checkbox.svg";
@internal-form-checkbox-indeterminate-image:     "~uikit/src/images/backgrounds/form-checkbox-indeterminate.svg";
@internal-divider-icon-image:                   "~uikit/src/images/backgrounds/divider-icon.svg";
@internal-list-bullet-image:                    "~uikit/src/images/backgrounds/list-bullet.svg";
@internal-nav-parent-close-image:               "~uikit/src/images/backgrounds/nav-parent-close.svg";
@internal-nav-parent-open-image:                "~uikit/src/images/backgrounds/nav-parent-open.svg";
@internal-accordion-open-image:                "~uikit/src/images/backgrounds/accordion-open.svg";
@internal-accordion-close-image:               "~uikit/src/images/backgrounds/accordion-close.svg";
@uk-primary: @red;

@import "shared.less";
@import "core.less";

* {
	box-sizing: border-box;
}

.uk-overlay,.uk-position-relative {
	z-index:11
}

.fb-justify-center {
	 justify-content: center;
}

.is-section {
	position:relative;
	z-index:1;
}

.is-wrapper {
	.uk-container {
		.uk-container {
			width:100%;
			margin:0 auto;
			padding:0;
			background:none;
			max-width:100%;
			box-sizing:border-box
		}
	}
}
.uk-text-small {
	font-size:11px !important
}

footer {
	font-size:90%;
	clear:both;
}

.is-wrapper {
	height:auto
}

.no-background-container {
	.uk-container {
		background:none !important;
		padding-bottom:0 ;
	}
}

.is-container {
	
	&.is-content-right {
		margin-right:0;
	}

	&.is-content-left {
		margin-left:0;
	}
}

.is-wrapper {
	.is-box {
		.is-container {
			margin-top:0;
			margin-bottom:0;
		}
	}
}

.is-section {
	.is-container {
		padding:30px;
	}
}

iframe {
	max-width:100%
}

.uk-light {

	color:#fff;

	p {
		color:#fff;
	}

	.uk-navbar-nav > li >
	a,button,.uk-button-text {
		color:#fff;
	}
}

.fb-overlay-content {
	position: relative;
	z-index: 12
}

.fb-overlay,.offline-overlay {
	background: rgba(0,0,0,0.3);
    position: absolute;
    z-index:1;
    top: 0;
    height: 100%;
    width: 100%;
 }

 .offline-overlay {
 	background: rgba(0,0,0,0.5);
 }

 #modal-menu {
	a {
		color:@text-color !important;
	}

	ul {

		padding:10px;

		li {
			list-style-type:disc;
			display:block;
		}

		a {
			font-size:100%;
		}
	}
}

.site-tagline {
	margin-bottom:8px;
}

@media screen and (max-width: @breakpoint-small) {
	h2.site-title {
		margin-top:6px !important;
		margin-bottom:4px !important;
		font-size:1.3rem;
	}

	.uk-breadcrumb {
		li {
			&:before {
				margin:0 4px !important;
			}
		}
	}

	.profile-image {
		margin-bottom:0;
		width:60px;
	}

}

@media screen and (min-width: @breakpoint-small) {
	#responsive-menu-icon {
		margin-top:45px !important;
	}
}

.site-contact-form {
	min-height:300px;
}

.quote {position:relative;margin:1.5em 0;}
.quote > i {position: absolute;top: -10px; left: -7px;font-size: 2em;}
.quote > small {margin-left:50px;opacity: 0.7;font-size: 1em;}
.quote > p {margin-left:50px;font-size: 1.5em;}
@media all and (max-width: 540px) {
    .quote > i {left: -15px;font-size:1.5em;}
    .quote > small {margin-left:20px;ont-size: 1em;}
    .quote > p {margin-left:20px;font-size: 1.2em;}
}

.uk-navbar-dropdown {
    background:#212121 !important;
    border-radius: 3px;
	box-shadow:0 0 2px 4px rgba(0, 0, 0, 0.2);

	* {
		text-align:left
	}
	a {
		color:#fff !important;
		border:0 !important;
		font-weight:normal !important
	}
}

.sidebar-sub-nav a {
	font-size:90%;
}

@media screen and (max-width: @breakpoint-medium) {
	#header-container {
		.profile-image {
			width:60px
		}
	}
}

.fb-white-bg {
	background:#fff;
}

hr {
	float:none;
}

.with-shadow {
    background: #fefefe;
    padding: 20px;
    box-shadow: 0 2px 6px rgba(0,0,0,0.06);
    margin-bottom: 30px;

    h1,h2 {
    	position:relative;
    	margin-bottom:10px;
    	padding-bottom:10px
    }

    h2:after,
    h1:after {
    	content: "";
	    height: 1px;
	    background: #348899;
	    width: 100px;
	    position: absolute;
	    left: 0;
	    bottom: 0;
    }
}

.uk-label {
    background:@fblue;

    &.uk-label-default {
        background:#fafafa;
        color:@text-color;
    }

    &.uk-label-green {
        background:@fgreen;
    }

    &.uk-label-red {
        background:@red;
    }

    &.uk-label-orange {
        background:@orange;
    }
}

.site-input {
	background: #fff;
    border: 1px solid rgba(0,0,0,0.05);
}

@media screen and (max-width:@breakpoint-small) {
	.profile-image {
		display:none;
	}
}

.header-language-selector {
    z-index:100;

    p,select {
        color:@text-color !important
    }

    select {
        background:#fff;
        border:1px solid rgba(0,0,0,0.1)
    }

    button {
        float:right;
        padding:10px;
        font-size: 10px !important;
        cursor: pointer;
        padding:4px 10px;
    }

    .uk-dropdown {
        margin-top:-5px
    }
}

.hide-on-mobile-1 {
    @media screen and (max-width:640px) {
        display:none;
    }
}

.hide-on-mobile-2 {
    @media screen and (max-width:960px) {
        display:none;
    }
}

.hide-on-mobile-3 {
    display:none;
}

// Date Display
.date-display {
    background:fade(#000, 70%);
    box-shadow:0 0 2px 1px rgba(0,0,0,0.06);
    text-align:center;
    display:block;
    width:40px;
    line-height:1.4em;
    height:40px;
    color:#fff;

    span {
        font-size:14px;
        width:40px;
        line-height:1em
    }

    .date-month {
        font-size: 12px;
        line-height: 0.4em;
    }

    .date-day {
        font-size: 20px;
        line-height: 1.4em;
    }

    .date-year {
        padding-top:2px;
        padding-bottom:4px
    }
}

.intro-text {
    font-size: 13px;
    line-height: 1.4em;
}

.fb-label {
    padding:4px;
    display:inline-block;
    margin-bottom:8px;
    background:@light-grey;
    border-radius:3px;
}

.article-more-button {
    color:@text-color;
    padding:4px 8px;

    * {
        color:@text-color
    }

    &:hover {
        color:#fff !important
    }
}

.uk-alert-notice {
    background: fade(@yellow, 20%);
    border: 1px solid fade(@yellow, 60%);
    border-radius: 2px;

    .btn {
        background: #fff;
        color: darken(@yellow, 10%);
        border: 1px solid darken(@yellow, 1%);
        margin-top: -4px;
        &:hover {
            background: #fff;
            color: @text-color
        }
    }
}


input,textarea {
	&.uk-form-danger {
		border: 1px solid @red;
		background:fade(@red,10%);
	}
}

.checkbox .checkbox-material {
    vertical-align: middle;
    position: relative;
    top: 3px;
  }
  .checkbox .checkbox-material:before {
    position: absolute;
    left: 8px;
    top: 2px;
    content: "";
    background-color: @fblue;
    height: 4px;
    width: 4px;
    border-radius: 100%;
    z-index: 1;
    opacity: 0;
    margin: 0;
  }
  .checkbox .checkbox-material .check {
    position: relative;
    display: inline-block;
    width: 20px;
    height: 20px;
    border: 2px solid;
    border-radius: 2px;
    overflow: hidden;
    z-index: 1;
  }
  .checkbox .checkbox-material .check:before {
    position: absolute;
    content: "";
    -webkit-transform: rotate(45deg);
            transform: rotate(45deg);
    display: block;
    margin-top: -4px;
    margin-left: 6px;
    width: 0;
    height: 0;
    box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0 inset;
    -webkit-animation: checkbox-off 0.3s forwards ease-out;
            animation: checkbox-off 0.3s forwards ease-out;
  }
  .checkbox input[type=checkbox]:focus + .checkbox-material .check:after {
    opacity: 0.2;
  }
  .checkbox input[type=checkbox]:checked + .checkbox-material .check:before {
    box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    -webkit-animation: checkbox-on 0.3s forwards ease-out;
            animation: checkbox-on 0.3s forwards ease-out;
  }
  .checkbox input[type=checkbox]:not(:checked) + .checkbox-material:before {
    -webkit-animation: rippleOff 700ms forwards ease-out;
            animation: rippleOff 700ms forwards ease-out;
  }
  .checkbox input[type=checkbox]:checked + .checkbox-material:before {
    -webkit-animation: rippleOn 700ms forwards ease-out;
            animation: rippleOn 700ms forwards ease-out;
  }
  .checkbox input[type=checkbox]:not(:checked) + .checkbox-material .check:after {
    -webkit-animation: rippleOff 700ms forwards ease-out;
            animation: rippleOff 700ms forwards ease-out;
  }
  .checkbox input[type=checkbox]:checked + .checkbox-material .check:after {
    -webkit-animation: rippleOn 700ms forwards ease-out;
            animation: rippleOn 700ms forwards ease-out;
  }
  .checkbox input[type=checkbox][disabled]:not(:checked) ~ .checkbox-material .check:before,
  .checkbox input[type=checkbox][disabled] + .circle {
    opacity: 0.5;
  }
  .checkbox input[type=checkbox][disabled] + .checkbox-material .check:after {
    background-color: rgba(0, 0, 0, 0.84);
    -webkit-transform: rotate(-45deg);
            transform: rotate(-45deg);
  }
  
  .coloured .checkbox-material .check {
    color: #2FAEF8;
  }
  .coloured .checkbox-material:before {
    background-color: #2FAEF8;
  }
  .coloured input[type=checkbox]:checked + .checkbox-material .check {
    color: #2FAEF8;
  }
  
  @-webkit-keyframes checkbox-on {
    0% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    }
    50% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    }
    100% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    }
  }
  
  @keyframes checkbox-on {
    0% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px;
    }
    50% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px 2px 0 11px;
    }
    100% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px;
    }
  }
  @-webkit-keyframes checkbox-off {
    0% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }
    25% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }
    50% {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      margin-top: -4px;
      margin-left: 6px;
      width: 0px;
      height: 0px;
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    }
    51% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      margin-top: -2px;
      margin-left: -2px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      margin-top: -2px;
      margin-left: -2px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
    }
  }
  @keyframes checkbox-off {
    0% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }
    25% {
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 20px -12px 0 11px, 0 0 0 0 inset;
    }
    50% {
      -webkit-transform: rotate(45deg);
              transform: rotate(45deg);
      margin-top: -4px;
      margin-left: 6px;
      width: 0px;
      height: 0px;
      box-shadow: 0 0 0 10px, 10px -10px 0 10px, 32px 0px 0 20px, 0px 32px 0 20px, -5px 5px 0 10px, 15px 2px 0 11px, 0 0 0 0 inset;
    }
    51% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      margin-top: -2px;
      margin-left: -2px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 10px inset;
    }
    100% {
      -webkit-transform: rotate(0deg);
              transform: rotate(0deg);
      margin-top: -2px;
      margin-left: -2px;
      width: 20px;
      height: 20px;
      box-shadow: 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0 0 0 0, 0px 0px 0 0px inset;
    }
  }
  @-webkit-keyframes rippleOn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(13, 13);
              transform: scale(13, 13);
    }
  }
  @keyframes rippleOn {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(13, 13);
              transform: scale(13, 13);
    }
  }
  @-webkit-keyframes rippleOff {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(13, 13);
              transform: scale(13, 13);
    }
  }
  @keyframes rippleOff {
    0% {
      opacity: 0.5;
    }
    100% {
      opacity: 0;
      -webkit-transform: scale(13, 13);
              transform: scale(13, 13);
    }
  }

