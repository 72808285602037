.fb-header-overlay {
	position:relative;
	z-index:10;
	height:100%;
	width:100%;
	position:absolute;
	top:0;
	left:0;
	content:"";
	background:rgba(0,0,0,0.35);
}

.fb-header-cover {
	// p,span {
	// 	color:#fff;
	// }

	a {
		// &:hover {
		// 	text-decoration:none;
		// 	color:@primary;

		// 	span {
		// 		color:@primary
		// 	}
		// }
	}

	.fb-align-icon {
		float:left;
		margin-top:2px
	}

	h1 {
		font-weight:300;
		font-family: "azo-sans-web";
	}


	.uk-dark {
		color:@text-color;

		p {
			color:@text-color;
		}

		.uk-navbar-nav > li >
		a,button,.uk-button-text,
		.uk-icon {
			color:@text-color;

			&:hover {
				color:@link-hover-color
			}
		}
	}
}

.header.uk-light {
	.social-links {
		a {
			color:#fff !important;
		}
	}
	a:hover,
	a:hover span,
	a span:hover {
		color:@text-color !important
	}
}